<template>
	<div>
		<div class="form-grid form-grid--2-reverse">
			<StyleSelectControl
				:element="element"
				:property="PROPERTY_FONT_WEIGHT"
				:style-options="fontWeightsSelect"
			/>
			<StyleSelectControl
				:element="element"
				:property="isMobileView ? PROPERTY_M_FONT_SIZE : PROPERTY_FONT_SIZE"
				:style-options="FONT_SIZE_STEPS"
			/>
		</div>
		<div class="decoration-options">
			<StyleToggleControl
				:element="element"
				:property="PROPERTY_TEXT_DECORATION"
				toggle-value="underline"
			/>
			<StyleToggleControl
				:element="element"
				:property="PROPERTY_TEXT_TRANSFORM"
				toggle-value="uppercase"
			/>
		</div>
	</div>
</template>

<script>
import {
	mapGetters,
	mapState,
	mapMutations,
} from 'vuex';

import StyleSelectControl
	from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/StyleSelectControl.vue';
import StyleToggleControl
	from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/StyleToggleControl.vue';
import {
	PROPERTY_FONT_WEIGHT,
	PROPERTY_FONT_SIZE,
	PROPERTY_TEXT_DECORATION,
	PROPERTY_TEXT_TRANSFORM,
	PROPERTY_COLOR,
	PROPERTY_M_FONT_SIZE,
} from '@/constants/globalStyles';
import {
	CHANGE_DRAWER_PAGE,
	mapActionsGui,
} from '@/store/builder/gui';

const FONT_WEIGHT_MAP = {
	100: 'Thin',
	200: 'Extra light',
	300: 'Light',
	400: 'Regular',
	500: 'Medium',
	600: 'Semi bold',
	700: 'Bold',
	800: 'Extra bold',
	900: 'Black',
};
const FONT_SIZE_STEPS = [
	{
		title: 8,
		value: '8px',
	},
	{
		title: 12,
		value: '12px',
	},
	{
		title: 14,
		value: '14px',
	},
	{
		title: 16,
		value: '16px',
	},
	{
		title: 18,
		value: '18px',
	},
	{
		title: 24,
		value: '24px',
	},
	{
		title: 32,
		value: '32px',
	},
	{
		title: 48,
		value: '48px',
	},
	{
		title: 64,
		value: '64px',
	},
	{
		title: 72,
		value: '72px',
	},
	{
		title: 96,
		value: '96px',
	},
	{
		title: 120,
		value: '120px',
	},
];

export default {
	components: {
		StyleToggleControl,
		StyleSelectControl,
	},
	props: {
		element: {
			type: String,
			default: null,
		},
		fontType: {
			type: String,
			default: 'primary',
		},
	},
	setup() {
		return {
			FONT_SIZE_STEPS,
			PROPERTY_FONT_WEIGHT,
			PROPERTY_FONT_SIZE,
			PROPERTY_M_FONT_SIZE,
			PROPERTY_TEXT_DECORATION,
			PROPERTY_TEXT_TRANSFORM,
		};
	},
	computed: {
		...mapGetters('fonts', [
			'getFontNames',
			'getAvailableFontWeights',
		]),
		...mapState(['website']),
		...mapState('gui', ['isMobileView']),
		fontWeightsSelect: ({
			getAvailableFontWeights,
			fontType,
		}) => getAvailableFontWeights[fontType]
			.map((fontWeight) => ({
				title: FONT_WEIGHT_MAP[fontWeight],
				value: fontWeight,
			})),
		elementColor: {
			get() {
				return this.website.styles[this.element][PROPERTY_COLOR];
			},
			set(color) {
				this.setStyleProperty({
					element: this.element,
					property: PROPERTY_COLOR,
					value: color,
				});
			},
		},
	},
	methods: {
		...mapActionsGui({ changePage: CHANGE_DRAWER_PAGE }),
		...mapMutations(['setStyleProperty']),
	},
};
</script>

<style scoped lang="scss">
@import '@/components/builder-drawers/drawers/partials/_forms.scss';

.decoration-options {
	margin-top: 12px;
}
</style>
